<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="800">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
                    {{ this.$route.meta.single }}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" v-model="valid" ref="form">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">
                                        {{ $t("almstfyd-mn-almaamlh") }}
                                        <span class="required">*</span>
                                    </h3>
                                    <v-text-field v-model="item.name" :placeholder="$t('almstfyd-mn-almaamlh')" filled outlined hide-details="auto" :rules="[$global.state.required()]" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">{{ $t("alwsf") }}</h3>
                                    <v-text-field v-model="item.description" :placeholder="$t('alwsf')" filled outlined hide-details="auto" />
                                </v-col>
                                <!-- <v-col cols="12" md="12">
                                    <h3 class="mb-2">وقت انتهاء المعاملة</h3>
                                    <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="وقت انتهاء المعاملة" outlined filled append-icon="mdi-calendar" readonly hide-details="auto" v-bind="attrs" v-on="on" v-model="item.expireDate"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.expireDate" locale="ar" color="primary" :allowed-dates="allowedDates"></v-date-picker>
                                    </v-menu>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="$global.state.loading" x-large color="primary" class="btn" :disabled="!valid">{{ $t("save") }}</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>{{ $t("cancel") }}</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            item: {},
            valid: false,
        };
    },
    computed: {
        isGuest() {
            return this.$route.path.search("guest") > -1;
        },
    },
    mounted() {
        this.reset();
        console.log(new Date().getDate());
        this.$eventBus.$on("fill-fields", () => {
            console.log(this.$store.state.itemDetails);
            console.log(this.$store.state.user.user.organization.name);
            // this.item.name = this.$store.state.user.user.organization.name
        });
    },

    methods: {
        reset() {
            this.item = {
                name: "",
                description: "",
                workflowId: "",
                // expireDate: "",
                statusId: null,
                organizationId: this.$store.state.user.user.organization.id,
            };
        },

        submit() {
            if (this.$store.state.itemDetails.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },

        addItem() {
            this.item.workflowId = this.$store.state.itemDetails.id;
            this.$global.state.loading = true;

            if (this.$store.state.user.role == "Guest") {
                this.item.statusId = "8605ae2f-ae5e-4dd9-81c0-113e82b5e166";
            } else {
                this.item.statusId = "203772c4-ad81-40a0-be50-d054b9b9dc8c";
            }
            this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$service.success(this.$t("tm-alidhafh-bnjah"));
                    this.$eventBus.$emit(`refresh`);
                    let item = res.data.result;
                    // this.$router.push({
                    //   path:
                    //     `/${this.isGuest ? "guest/" : ""}orderNode/` +
                    //     item.id +
                    //     "/" +
                    //     item.name,
                    // });
                    console.log(
                        `/${this.isGuest ? "guest/" : "/"}orderNode/` +
                        item.id +
                        "/" +
                        item.name
                    );
                    this.close();
                })
                .catch((err) => {
                    this.$service.faild(err.response.data.message);
                })
                .finally(() => (this.$global.state.loading = false));
        },

        editItem() {
            this.$global.state.loading = true;
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$service.success(this.$t("tm-altadyl-bnjah"));
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$service.faild(err.response.data.message);
                })
                .finally(() => (this.$global.state.loading = false));
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

        getClients() {
            this.$global.dispatch(`getClients`);
        },

        getGuarantees() {
            this.$global.dispatch(`getGuarantees`);
        },

        getProducts() {
            this.$global.dispatch(`getProducts`);
        },
        allowedDates: (val) =>
            parseInt(val.split("-")[2], 10) >= new Date().getDate(),
    },
};
</script>
